import React from 'react'
// import SignUp from './Pages/SingUp/SignUp';
import SignUpForm from './Pages/SingUp/SignUp';


export default function Collector() {
  return (
    <>
        <div className="collector">
            {/* <SignUp/> */}
            <SignUpForm/>
        </div>
    </>
  )
}
